import styled from "styled-components";
import { TABLET_MAX_WIDTH } from "@constants";

export const ProudSectionWrapper = styled.div`
    padding: 5rem 0;

    & .page-heading__heading {
        margin-bottom: 2.5rem;
        text-align: center;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        padding: 2.5rem 0;

        & .page-heading__heading {
            text-align: left;
            margin-bottom: 1.5rem;
        }
    }
`;

export const ProudLogoContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

export const ProudLogoRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;

    &:not(&:first-child) {
        margin-top: 2.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        column-gap: 6.5rem;

        &:nth-child(2) {
            justify-content: space-around;
            padding: 0 10.625rem;
        }
    }
`;

export const ProudLogo = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15rem;
    height: 15rem;

    & img {
        width: 100%;
        max-height: 12.5rem;
    }

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        width: 7.5rem;
        height: 7.5rem;

        & img {
            width: 100%;
            max-height: 6.25rem;
        }
    }
`;
